import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import {
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import {
  Topbar,
  Footer,
  Sidebar,
} from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
}));

const Main = ({children, isAuthenticated}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(
    theme.breakpoints.up('md'),
    {
      defaultMatches: true,
    }
  );

  const [openSidebar, setOpenSidebar] = useState(
    false
  );

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}>
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        isAuthenticated={isAuthenticated}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  // themeToggler: PropTypes.func.isRequired,
  // themeMode: PropTypes.string.isRequired,
};

export default Main;
