import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        EastCoastEfoil.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    // background: theme.palette.grey[200],
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Container
      maxWidth="md"
      component="footer"
      className={classes.footer}>
      <Grid
        container
        spacing={4}
        justify="space-evenly">
        {footers.map((footer) => (
          <Grid
            item
            xs={6}
            sm={3}
            key={footer.title}>
            <Typography
              variant="h6"
              color="textPrimary"
              gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.description.map((item) => (
                <li key={item['name']}>
                  <Link
                    href={item['url']}
                    variant="subtitle1"
                    color="textSecondary">
                    {item['name']}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

const footers = [
  {
    title: 'Company',
    description: [
      {name: 'Contact Us', url: '/contact'},
    ],
  },
  {
    title: 'Product',
    description: [
      {name: 'Home', url: '/'},
      {name: 'Pricing', url: '/'},
    ],
  },
  {
    title: 'Legal',
    description: [
      {
        name: 'Privacy policy',
        url: '/privacy-policy',
      },
      {
        name: 'Terms of Use',
        url: '/terms-of-use',
      },
    ],
  },
];
