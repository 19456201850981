/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import ReactGA from 'react-ga';
import {createBrowserHistory} from 'history';

import * as serviceWorker from './serviceWorker';
import App from './App';

ReactGA.initialize('UA-189848218-1');
ReactGA.pageview('/');

let localAlbums = localStorage.getItem(
  'selectedAlbums'
);

const initialAlbums = localAlbums
  ? JSON.parse(localAlbums)
  : [];

ReactDOM.render(
  // <React.StrictMode history={history}>
  <Router basename="/">
    <App initialAlbums={initialAlbums} />
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
