/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React, { useState, useEffect } from 'react';
import Routes from './routes';
import { AppContext } from './libs/contextLib';
import Cookies from 'js-cookie';
import { ErrorBoundary } from './components/utils/ErrorBoundary';
import { onError } from './libs/errorLib';
import { createToken } from './libs/utils';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'assets/css/index.css';

import 'aos/dist/aos.css';



function App({ initialAlbums }) {
  const [
    selectedAlbums,
    setSelectedAlbums,
  ] = useState(initialAlbums);

  useEffect(() => {
    if (!Cookies.get('userID')) {
      Cookies.set('userID', createToken());
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'selectedAlbums',
      JSON.stringify(selectedAlbums)
    );
  }, [selectedAlbums]);

  return (
    <div>
      <AppContext.Provider
        value={{
          selectedAlbums,
          setSelectedAlbums,
        }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;
