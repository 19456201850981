// ===================== //
// ----API REQUESTS ---- //
//===================== //

export function parseJSON(data) {
  // MongoDB will send JSON with some of the values as objects
  //
  // [{"wheelbarrows:24", "timestamp":{$Date:"2020-06-12T13:07:26Z"} ,...}, ...]
  // timestamp in this case needs to be converted to a string that can be displayed
  // in a table
  //
  // Basically flattens a list of objects formatted [{entries}, {entries}, ]
  // to not include any sub objects in the 'entries'

  var out = [];
  data.forEach((row) => {
    var entry = {};
    for (let [
      key,
      value,
    ] of Object.entries(row)) {
      if (typeof value === 'object') {
        for (var prop in value) {
          if (prop === '$date') {
            value = createPrettyDate(
              value[prop]
            );
          } else {
            // FIXME, Implicitly assumes anything that's not a date is null
            // value = null; old
            value = '';
          }
          break;
        }
      }
      entry[key] = value;
    }
    out.push(entry);
  });
  return out;
}

// ===================== //
// -----FORMATTING ---- //
//===================== //

export function formatWeekdayMonthDate(
  dateStr
) {
  // function to transform a date string to a pretty date to display on table
  // "2020-06-12T13:07:26Z" -> "Friday, June 12"
  // "2020-06-18T16:43:00Z" -> "Thursday, June 18"
  if (!dateStr) {
    return null;
  }
  var date = new Date(dateStr);
  var day = date.getDate();
  var month = date.toLocaleString(
    'default',
    { month: 'long' }
  );
  var weekday = date.toLocaleString(
    'default',
    { weekday: 'long' }
  );
  return `${weekday}, ${month} ${day}`;
}

export function createPrettyDate(
  dateStr
) {
  // function to transform a date string to a pretty date to display on table
  // 2020-06-12T13:07:26Z -> 1:07pm Jun 12
  // 2020-06-18T16:43:00Z -> 4:43pm Jun 18

  if (!dateStr) {
    return null;
  }
  var date = new Date(dateStr);

  var month = date.toLocaleString(
    'default',
    { month: 'short' }
  );
  var day = date.getDate();
  var time = createTimeFromDate(date);

  return `${time} ${month} ${day}`;
}

export function checkIsDesktop() {
  let width = window.innerWidth;
  if (width > 780) {
    return true;
  } else {
    return false;
  }
}

export function createTimeFromDate(
  date
) {
  // function to extract the time out of a date
  // Date("2020-06-12T13:07:26Z") -> 1:07pm
  // Date("2020-06-18T08:43:00Z") -> 8:43am

  var h = date.getUTCHours();
  var m = date.getMinutes();
  var x = h >= 12 ? 'pm' : 'am';
  h = h % 12;
  h = h ? h : 12;
  m = m < 10 ? '0' + m : m;
  var mytime = h + ':' + m + '' + x;
  return mytime;
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return (
        txt.charAt(0).toUpperCase() +
        txt.substr(1).toLowerCase()
      );
    }
  );
}

export function roundNum(num) {
  // (-1,1 )two decimels
  // (>1, < 10) or < -1 one decimel
  // > 10, truncate
  if (num == 0) {
    return 0;
  } else if ((num >= -1) & (num <= 1)) {
    return num.toFixed(2);
  } else if (
    (num < -1) |
    ((num > 1) & (num <= 10))
  ) {
    return num.toFixed(1);
  } else {
    return num.toFixed(0);
  }
}

export function createToken() {
  return Math.random().toString(36).substr(2); // remove `0.`
};
