import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Toolbar} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles({
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    // padding: theme.spacing(0, 2),
    // [theme.breakpoints.up('sm')]: {
    //   padding: theme.spacing(0, 8),
    // },
  },
  logoContainer: {
    width: 100,
    height: 28,
    // [theme.breakpoints.up('md')]: {
    //   width: 120,
    //   height: 32,
    // },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
});

const Topbar = ({className, ...rest}) => {
  const classes = useStyles();

  return (
    <Toolbar
      className={clsx(classes.toolbar, className)}
      {...rest}>
      <div className={classes.logoContainer}>
        <Link to="/" title="home">
          <Typography
            className={classes.logoImage}
            variant="h4"
            color="primary">
            Katanagi
          </Typography>
        </Link>
      </div>
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
