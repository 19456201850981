import React from 'react';
import {Switch} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';

import UnauthenticatedRoute from 'components/utils/UnauthenticatedRoute';
// import AuthenticatedRoute from 'components/utils/AuthenticatedRoute';
import asyncComponent from 'components/utils/AsyncComponent';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts';
import WithLayout from 'WithLayout';

const AsyncNotFound = asyncComponent(
  () => import('./views/NotFound')
);
const AsyncLandingPage = asyncComponent(
  () => import('./views/DesktopApp')
);

const AsyncPrivacyPolicy = asyncComponent(
  () => import('./views/PrivacyPolicy')
);
const AsyncTermsOfUse = asyncComponent(
  () => import('./views/TermsOfUse')
);
const AsyncContactPage = asyncComponent(
  () => import('./views/ContactPage')
);

const AsyncCreatePoster = asyncComponent(
  () => import('./views/CreatePoster')
);

export default function Routes({childProps}) {
  const location = useLocation();
  ReactGA.pageview(location.pathname);

  return (
    <Switch>
      <UnauthenticatedRoute
        exact
        path="/create-poster"
        props={childProps}
        component={AsyncCreatePoster}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/contact"
        props={childProps}
        component={AsyncContactPage}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/privacy-policy"
        props={childProps}
        component={AsyncPrivacyPolicy}
        layout={MainLayout}
      />

      <UnauthenticatedRoute
        exact
        path="/terms-of-use"
        props={childProps}
        component={AsyncTermsOfUse}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/"
        props={childProps}
        component={AsyncLandingPage}
        layout={MainLayout}
      />

      {/* Finally, catch all unmatched routes */}

      <WithLayout
        layout={MainLayout}
        component={AsyncNotFound}
      />
    </Switch>
  );
}
